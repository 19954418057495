import { GetServerSideProps } from 'next';
import { getCsrfToken, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

export default function SignIn({ csrfToken }: { csrfToken: string }) {
  const session = useSession();
  const router = useRouter();

  if (session.status === 'authenticated') {
    router.replace('/me');
  }

  return (
    <form
      method="post"
      action={`/api/auth/callback/credentials?callbackUrl=/home2`}
    >
      <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
      <label>
        Username
        <input name="username" type="text" />
      </label>
      <label>
        Password
        <input name="password" type="password" />
      </label>
      <button type="submit">Sign in</button>
    </form>
  );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  return {
    props: {
      csrfToken: await getCsrfToken(context),
    },
  };
};
